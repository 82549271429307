@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");
@import url("https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,400,500,600,700,800,900&display=swap");

body {
  font-family: "Work Sans", sans-serif;
  color: #5c6062;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 18px;
}

a {
  text-decoration: none;
  color: #515b69;
}

.m {
  display: none;
}
.mo {
  display: block;
}
.mo.inline {
  display: inline-block;
}
@media (max-width: 915px) {
  .mo,
  .mo.inline {
    display: none;
  }
}
#canvas {
  margin-bottom: 166px;
}

@media (max-width: 900px) {
  .container {
    &.form {
      max-width: none;
    }
  }
}

.toast-progress-bar {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.Toastify__toast {
  min-height: 50px;
}

.Toastify__toast--success {
  border-radius: 10px !important;
  background: #5fb540 !important;
}
